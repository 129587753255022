import { type SVGProps, memo } from "react";

const SvgLinkedInIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.8156 0.631836H1.18125C0.528125 0.631836 0 1.14746 0 1.78496V15.4756C0 16.1131 0.528125 16.6318 1.18125 16.6318H14.8156C15.4688 16.6318 16 16.1131 16 15.4787V1.78496C16 1.14746 15.4688 0.631836 14.8156 0.631836ZM4.74687 14.2662H2.37188V6.62871H4.74687V14.2662ZM3.55938 5.58809C2.79688 5.58809 2.18125 4.97246 2.18125 4.21309C2.18125 3.45371 2.79688 2.83809 3.55938 2.83809C4.31875 2.83809 4.93437 3.45371 4.93437 4.21309C4.93437 4.96934 4.31875 5.58809 3.55938 5.58809ZM13.6344 14.2662H11.2625V10.5537C11.2625 9.66934 11.2469 8.52871 10.0281 8.52871C8.79375 8.52871 8.60625 9.49434 8.60625 10.4912V14.2662H6.2375V6.62871H8.5125V7.67246H8.54375C8.85938 7.07246 9.63438 6.43809 10.7875 6.43809C13.1906 6.43809 13.6344 8.01934 13.6344 10.0756V14.2662Z"
      fill="currentColor"
    />
  </svg>
);

export const LinkedInIcon = memo(SvgLinkedInIcon);
