import type React from "react";
import { Button } from "../../components/button/button";
import { SocialIcon } from "../../components/social-icon/social-icon";
import { LogoIcon } from "../../primitives/logo";
import { Text } from "../../primitives/text/text";
import { cn } from "../../utils/cn";

interface FooterProps {
  theme?: "dark" | "light";
}

export const Footer: React.FC<FooterProps> = ({ theme }) => {
  return (
    <footer
      className={cn("w-full bg-bg-primary py-8 lg:px-10 px-4", {
        "dark text-text-primary": theme === "dark",
      })}
    >
      <div className="px-4 sm:px-6 lg:px-8 grid grid-cols-12 lg:gap-8 bg-bg-secondary w-full lg:p-10 p-4">
        <FooterLeft />
        <FooterRight />
        <div className="lg:hidden flex items-center gap-x-2 mt-4">
          <SocialIcons />
        </div>
      </div>
      <FooterBottom />
    </footer>
  );
};

const FooterLeft: React.FC = () => (
  <div className="lg:col-span-3 col-span-12 flex flex-col lg:justify-between lg:h-full">
    <div className="flex items-center space-x-2 lg:mb-6">
      <LogoIcon className="h-10 w-auto" />
      <h1 className="text-xl">flipside</h1>
    </div>
    <div className="lg:space-x-4 items-center hidden lg:flex">
      <SocialIcons />
    </div>
  </div>
);

const FooterRight: React.FC = () => (
  <div className="col-span-12 lg:col-span-9 grid lg:grid-cols-4 gap-8 grid-cols-2">
    <FooterColumn
      title="Offering"
      items={[
        { label: "For Chains", href: "/home/chains" },
        { label: "For Analysts", href: "/home/analysts" },
        { label: "For Explorers", href: "/home/explorers" },
      ]}
    />
    <FooterColumn
      title="About"
      items={[
        { label: "Company", href: "/home/about" },
        {
          label: "Resources",
          href: "https://flipsidecrypto.xyz/fc/resources",
          target: "_blank",
        },
        {
          label: "Documentation",
          href: "https://docs.flipsidecrypto.xyz/",
          target: "_blank",
        },
        {
          label: "Careers",
          href: "https://flipsidecrypto.xyz/home/careers",
          target: "_blank",
        },
      ]}
    />
    <FooterColumn
      title="Data"
      items={[
        { label: "Insights", href: "https://flipsidecrypto.xyz/insights/dashboards" },
        { label: "Studio", href: "https://flipsidecrypto.xyz/studio" },
        { label: "LiveQuery", href: "https://flipsidecrypto.xyz/livequery" },
        { label: "API", href: "https://flipsidecrypto.xyz/settings/api" },
      ]}
    />
    <FooterColumn
      title="Quests"
      items={[
        { label: "Quests", href: "https://flipsidecrypto.xyz/earn" },
        {
          label: "Grail",
          href: "https://flipsidecrypto.xyz/grail/near/s2/final",
        },
      ]}
    />
  </div>
);

interface FooterColumnProps {
  title: string;
  items: { label: string; href: string; target?: "_blank" }[];
}

const FooterColumn: React.FC<FooterColumnProps> = ({ title, items }) => (
  <div className="flex flex-col gap-y-4 pt-4">
    <Text size="sm" weight="medium" className="text-text-tertiary">
      {title}
    </Text>
    <ul className="">
      {items.map((item, index) => (
        <li key={index} className="text-left">
          <FooterLink href={item.href} target={item.target}>
            {item.label}
          </FooterLink>
        </li>
      ))}
    </ul>
  </div>
);

interface FooterLinkProps {
  href: string;
  children: React.ReactNode;
  target?: "_blank";
}

const FooterLink: React.FC<FooterLinkProps> = ({ href, children, target }) => (
  <Button variant="ghost" asChild className="justify-start px-0 max-md:pt-0 dark:text-white hover:bg-transparent">
    <a href={href} target={target}>
      {children}
    </a>
  </Button>
);

const FooterBottom: React.FC = () => (
  <div className="mt-8">
    <div className="flex justify-between md:items-center">
      <Text variant={"caption"} className="text-muted-foreground">
        © {new Date().getFullYear()} Flipside Crypto
      </Text>
      <div className="flex items-center gap-x-4">
        <FooterLink href="https://flipsidecrypto.xyz/terms">Terms & Conditions</FooterLink>
        <FooterLink href="https://flipsidecrypto.xyz/privacy">Privacy Policy</FooterLink>
      </div>
    </div>
  </div>
);

const SocialIcons = () => {
  return (
    <>
      <SocialIcon type="x" href="https://x.com/flipsidecrypto" />
      <SocialIcon type="discord" href="https://discord.com/invite/flipside" />
      <SocialIcon type="linkedin" href="https://www.linkedin.com/company/flipside-crypto" />
    </>
  );
};
