import type * as React from "react";

export const TrackedLink = ({
  href,
  onClick,
  ...props
}: {
  href: string;
  onClick?: (() => void) | undefined;
  children: React.ReactNode;
  className?: string;
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onClick?.();
    window.location.href = href;
  };

  return (
    <a href={href} onClick={handleClick} {...props}>
      {props.children}
    </a>
  );
};
