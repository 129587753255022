import type { Team } from "@fscrypto/domain/team";
import type { User } from "@fscrypto/domain/user";
import { ChevronDown, MenuIcon, MoveUpRight } from "lucide-react";
import type React from "react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/accordion/accordion";
import { Avatar, AvatarFallback, AvatarImage } from "../../components/avatar/avatar";
import { Button } from "../../components/button/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/dropdown-menu/dropdown-menu";
import { TrackedLink } from "../../components/tracked-click/TrackedLink";
import { AnalystsIcon } from "../../icons/AnalystsIcon";
import { BlockchainsIcon } from "../../icons/BlockchainsIcon";
import { ExplorerIcon } from "../../icons/ExplorerIcon";
import { LogoIcon } from "../../primitives/logo";
import { Text } from "../../primitives/text/text";
import { cn } from "../../utils/cn";
import ConnectWallet from "../connect-wallet/connect-wallet";

interface HeaderProps {
  rewardCount?: number;
  renderMenuContent?: React.ReactNode;
  authMenuContent?: React.ReactNode;
  user?: User | null;
  team?: Team | null;
  dark?: boolean;
  transparent?: boolean;
  tracking?: (event: string) => void;
  onLogout?: () => void;
  connectWalletConfig?: {
    dynamicEnvironmentId: string;
    myNearWalletCallbackUrl: string;
  };
  showInsightsNavItem?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  rewardCount = 0,
  renderMenuContent,
  authMenuContent,
  user,
  team,
  dark,
  transparent,
  tracking,
  onLogout,
  connectWalletConfig,
  showInsightsNavItem = false,
}) => {
  return (
    <header
      className={cn("bg-bg-primary w-full relative z-30 bg-bg-black py-6 md:py-4", {
        dark: dark,
        "bg-transparent": !!transparent,
        "bg-bg-primary": !transparent,
      })}
    >
      <div className="w-full items-center justify-between px-10 hidden min-[1090px]:flex">
        {showInsightsNavItem ? (
          <div className="flex items-center space-x-16">
            <HeaderLogo />
            <HeaderLeftNav tracking={tracking} showInsightsNavItem={showInsightsNavItem} />
          </div>
        ) : (
          <>
            <HeaderLogo />
            <HeaderLeftNav tracking={tracking} showInsightsNavItem={showInsightsNavItem} />
            <NavDivider />
          </>
        )}

        <div className="flex items-center">
          <HeaderRightNav tracking={tracking} showInsightsNavItem={showInsightsNavItem} />
          <HeaderAuth
            rewardCount={rewardCount}
            renderMenuContent={renderMenuContent}
            authMenuContent={authMenuContent}
            user={user}
            team={team}
            tracking={tracking}
            onLogout={onLogout}
            connectWalletConfig={connectWalletConfig}
            dark={dark}
          />
        </div>
      </div>
      <MobileNav user={user} connectWalletConfig={connectWalletConfig} showInsightsNavItem={showInsightsNavItem} />
    </header>
  );
};

const NavDivider: React.FC = () => <div className="h-8 w-px bg-border-secondary" />;

const HeaderLogo: React.FC = () => (
  <a href="/" aria-label="Flipside" className="flex items-center space-x-2">
    <LogoIcon className="h-8 w-auto" />
    <h1 className="text-xl">flipside</h1>
  </a>
);

const HeaderLeftNav: React.FC<{
  tracking?: (event: string) => void;
  showInsightsNavItem?: boolean;
}> = ({ tracking, showInsightsNavItem }) => {
  const pathname = typeof window !== "undefined" ? window.location.pathname : "";
  const isInsights =
    pathname.startsWith("/insights") &&
    !pathname.startsWith("/insights/dashboards") &&
    !pathname.startsWith("/insights/verified-insights") &&
    !pathname.startsWith("/insights/queries") &&
    !pathname.startsWith("/insights/analysts") &&
    !pathname.startsWith("/insights/teams");

  return (
    <nav className="hidden space-x-4 md:flex">
      {showInsightsNavItem && (
        <HeaderNavItem href="/insights" onClick={tracking && (() => tracking("nav_click_insights"))}>
          <div className={`flex items-center space-x-1 ${isInsights ? "text-primary" : ""}`}>
            <img
              src="https://flipsidecrypto.xyz/images/icons/flip-ai.svg"
              alt="Insights Icon"
              className="w-3 h-3 mr-1"
            />
            Insights
          </div>
        </HeaderNavItem>
      )}
      <HeaderNavItem
        href="/insights/dashboards"
        onClick={tracking && (() => tracking(showInsightsNavItem ? "nav_click_data" : "nav_click_insights"))}
      >
        {showInsightsNavItem ? "Dashboards" : "Insights"}
      </HeaderNavItem>
      {showInsightsNavItem ? (
        <>
          <SolutionsMenu tracking={tracking} className="px-1" />
          <AboutMenu tracking={tracking} className="px-1" />
        </>
      ) : (
        <>
          <HeaderNavItem href="/edit" onClick={tracking && (() => tracking("nav_click_studio"))}>
            Studio
          </HeaderNavItem>
          <HeaderNavItem href="/earn/" onClick={tracking && (() => tracking("nav_click_quests"))}>
            Quests
          </HeaderNavItem>
        </>
      )}
    </nav>
  );
};

const HeaderRightNav: React.FC<{ tracking?: (event: string) => void; showInsightsNavItem?: boolean }> = ({
  tracking,
  showInsightsNavItem,
}) => {
  const pathname = typeof window !== "undefined" ? window.location.pathname : "";
  const isEarn = pathname.startsWith("/earn");

  return (
    <nav
      className={`hidden space-x-4 md:flex justify-between items-center flex-1 max-w-[600px] ${showInsightsNavItem ? "max-w-[550px]" : ""}`}
    >
      {showInsightsNavItem ? (
        <div className="flex items-center mr-6">
          <HeaderNavItem href="/studio" onClick={tracking && (() => tracking("nav_click_studio"))} className="mr-2">
            <Button
              variant="outline"
              className={cn("border-gray-400 dark:border-primary hover:bg-primary/20", {
                "border-primary": isEarn,
              })}
            >
              Studio
              <MoveUpRight className="size-4 ml-2" />
            </Button>
          </HeaderNavItem>
          <HeaderNavItem href="/earn/" onClick={tracking && (() => tracking("nav_click_quests"))}>
            <Button
              variant="outline"
              className={cn("border-gray-400 dark:border-primary hover:bg-primary/20", {
                "border-primary": isEarn,
              })}
            >
              Quests
              <MoveUpRight className="size-4 ml-2" />
            </Button>
          </HeaderNavItem>
        </div>
      ) : (
        <>
          <HeaderNavItem href="/home/chains" onClick={tracking && (() => tracking("nav_click_chains"))}>
            For Chains
          </HeaderNavItem>
          <HeaderNavItem href="/home/analysts" onClick={tracking && (() => tracking("nav_click_analysts"))}>
            For Analysts
          </HeaderNavItem>
          <HeaderNavItem href="/home/explorers" onClick={tracking && (() => tracking("nav_click_explorers"))}>
            For Explorers
          </HeaderNavItem>
          <AboutMenu tracking={tracking} />
        </>
      )}
    </nav>
  );
};

interface HeaderNavItemProps {
  href: string;
  children: React.ReactNode;
  onClick?: (() => void) | undefined;
  className?: string;
}

const HeaderNavItem: React.FC<HeaderNavItemProps> = ({ href, children, onClick, className }) => (
  <TrackedLink href={href} className={cn("text-sm block px-2 relative font-medium", className)} onClick={onClick}>
    {children}
  </TrackedLink>
);

const HeaderAuth: React.FC<HeaderProps> = ({
  rewardCount = 0,
  team,
  renderMenuContent,
  authMenuContent,
  user,
  onLogout,
  dark,
  connectWalletConfig,
  tracking,
}) => (
  <div className="flex items-center space-x-4">
    {authMenuContent && <div className="ml-2">{authMenuContent}</div>}
    {user ? (
      <>
        <HeaderNavItem href="/rewards">
          Rewards
          {rewardCount > 0 && (
            <div className="absolute right-0 top-0 -translate-y-1/2 translate-x-1/2 transform rounded-full bg-red-500 px-1 text-xs text-white">
              {rewardCount}
            </div>
          )}
        </HeaderNavItem>
        <UserMenu
          user={user}
          team={team}
          renderMenuContent={renderMenuContent}
          onLogout={onLogout}
          dark={dark}
          connectWalletConfig={connectWalletConfig}
        />
      </>
    ) : (
      <>
        <HeaderAuthButton
          variant="ghost"
          href="/home/login"
          onClick={tracking ? () => tracking("nav_click_login") : undefined}
        >
          Log in
        </HeaderAuthButton>
        <HeaderAuthButton
          variant="default"
          href="/home/login?screen_hint=signup"
          onClick={tracking ? () => tracking("nav_click_signup") : undefined}
        >
          Sign up
        </HeaderAuthButton>
      </>
    )}
  </div>
);

interface HeaderAuthButtonProps {
  variant: "default" | "ghost" | "outline";
  href: string;
  children: React.ReactNode;
  className?: string;
  onClick?: (() => void) | undefined;
}

const HeaderAuthButton: React.FC<HeaderAuthButtonProps> = ({ variant, href, children, className, onClick }) => (
  <TrackedLink href={href} onClick={onClick}>
    <Button variant={variant} className={className}>
      {children}
    </Button>
  </TrackedLink>
);

interface UserMenuProps {
  renderMenuContent?: React.ReactNode;
  user: User;
  team?: Team | null;
  onLogout?: () => void;
  dark?: boolean;
  connectWalletConfig?: HeaderProps["connectWalletConfig"];
}

const UserMenu: React.FC<UserMenuProps> = ({ user, team, renderMenuContent, onLogout, dark, connectWalletConfig }) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <div className="items-center cursor-pointer flex space-x-1">
        <Avatar className="size-6">
          <AvatarImage src={user.avatarUrl} alt={user.username} />
          <AvatarFallback>{user.username.charAt(0).toUpperCase()}</AvatarFallback>
        </Avatar>
        {team && (
          <div className="bg-bg-tertiary/50 p-1 pr-3 rounded-full flex items-center space-x-2 text-sm">
            <Avatar className="size-6">
              <AvatarImage src={team.avatarUrl} />
              <AvatarFallback>{team.name.charAt(0).toUpperCase()}</AvatarFallback>
            </Avatar>
            <span>{team.name}</span>
          </div>
        )}
        <ChevronDown className="size-4 stroke-1" />
      </div>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end" className="w-60">
      <div className="px-3 py-2 text-sm font-normal">
        <div className="flex flex-col space-y-1">
          <p>{user.username}</p>
          <p className="text-text-tertiary">{user.email}</p>
        </div>
      </div>
      {renderMenuContent && (
        <>
          <DropdownMenuSeparator />
          {renderMenuContent}
        </>
      )}
      <DropdownMenuSeparator />
      {team && (
        <DropdownMenuItem asChild>
          <a className="h-full w-full cursor-pointer" href={`/${team.slug}`}>
            Team Profile
          </a>
        </DropdownMenuItem>
      )}
      <DropdownMenuItem asChild>
        <a className="h-full w-full cursor-pointer" href={`/${user.username}`}>
          Profile
        </a>
      </DropdownMenuItem>
      {connectWalletConfig && (
        <ConnectWallet
          config={{
            dynamicEnvironmentId: connectWalletConfig.dynamicEnvironmentId,
            myNearWalletCallbackUrl: connectWalletConfig.myNearWalletCallbackUrl,
            theme: dark ? "dark" : "light",
          }}
        >
          <DropdownMenuItem asChild onSelect={(event) => event.preventDefault()}>
            <span className="h-full w-full cursor-pointer">Wallets</span>
          </DropdownMenuItem>
        </ConnectWallet>
      )}
      <DropdownMenuItem asChild>
        <a className="h-full w-full cursor-pointer" href="/settings/plan">
          Settings
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <a className="h-full w-full cursor-pointer" href="/rewards">
          Rewards
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <a className="h-full w-full cursor-pointer" href="/earn/history">
          Quest History
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <form action="/home/logout" method="post" onSubmit={onLogout}>
          <button type="submit" className="h-full w-full cursor-pointer text-left">
            Log out
          </button>
        </form>
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
);

const AboutMenuContent = ({ tracking }: { tracking?: (event: string) => void }) => (
  <>
    <DropdownMenuItem>
      <a href="/home/about" onClick={tracking && (() => tracking("nav_click_company"))}>
        <Text variant="button">Company</Text>
      </a>
    </DropdownMenuItem>
    <DropdownMenuItem>
      <a
        href="https://flipsidecrypto.xyz/fc/resources"
        target="_blank"
        onClick={tracking && (() => tracking("nav_click_resources"))}
        rel="noreferrer"
      >
        <Text variant="button">Resources</Text>
      </a>
    </DropdownMenuItem>
    <DropdownMenuItem>
      <a
        href="https://docs.flipsidecrypto.xyz/"
        target="_blank"
        onClick={tracking && (() => tracking("nav_click_documentation"))}
        rel="noreferrer"
      >
        <Text variant="button">Documentation</Text>
      </a>
    </DropdownMenuItem>
    <DropdownMenuItem>
      <a
        href="https://flipsidecrypto.xyz/home/careers"
        target="_blank"
        onClick={tracking && (() => tracking("nav_click_careers"))}
        rel="noreferrer"
      >
        <Text variant="button">Careers</Text>
      </a>
    </DropdownMenuItem>
  </>
);

export const AboutMenu = ({ tracking, className }: { tracking?: (event: string) => void; className?: string }) => (
  <DropdownMenu>
    <DropdownMenuTrigger>
      <div
        className={cn("flex items-center space-x-1 px-7 cursor-pointer", className)}
        onClick={tracking && (() => tracking("nav_click_about"))}
      >
        <span className="text-sm font-medium">About</span>
        <ChevronDown className="size-4 stroke-1" />
      </div>
    </DropdownMenuTrigger>
    <DropdownMenuContent className="mt-4">
      <AboutMenuContent tracking={tracking} />
    </DropdownMenuContent>
  </DropdownMenu>
);

const SolutionsMenuContent = ({ tracking }: { tracking?: (event: string) => void }) => (
  <>
    <DropdownMenuItem>
      <a href="/home/chains" onClick={tracking && (() => tracking("nav_click_chains"))} className="flex items-center">
        <BlockchainsIcon className="size-5 mr-2" />
        <div className="flex flex-col space-y-1">
          <Text variant="button">For Blockchains</Text>
          <Text variant="caption" className="text-text-tertiary">
            Drive measurable growth for your ecosystem
          </Text>
        </div>
      </a>
    </DropdownMenuItem>
    <DropdownMenuItem>
      <a
        href="/home/analysts"
        onClick={tracking && (() => tracking("nav_click_analysts"))}
        className="flex items-center"
      >
        <AnalystsIcon className="size-5 mr-2" />
        <div className="flex flex-col space-y-1">
          <Text variant="button">For Analysts</Text>
          <Text variant="caption" className="text-text-tertiary">
            Free data and AI tools for building dashboards
          </Text>
        </div>
      </a>
    </DropdownMenuItem>
    <DropdownMenuItem>
      <a
        href="/home/explorers"
        onClick={tracking && (() => tracking("nav_click_explorers"))}
        className="flex items-center"
      >
        <ExplorerIcon className="size-5 mr-2" />
        <div className="flex flex-col space-y-1">
          <Text variant="button">For Explorers</Text>
          <Text variant="caption" className="text-text-tertiary">
            Earn rewards for completing onchain activities
          </Text>
        </div>
      </a>
    </DropdownMenuItem>
  </>
);

export const SolutionsMenu = ({ tracking, className }: { tracking?: (event: string) => void; className?: string }) => (
  <DropdownMenu>
    <DropdownMenuTrigger>
      <div
        className={cn("flex items-center space-x-1 px-7 cursor-pointer", className)}
        onClick={tracking && (() => tracking("nav_click_about"))}
      >
        <span className="text-sm font-medium">Solutions</span>
        <ChevronDown className="size-4 stroke-1" />
      </div>
    </DropdownMenuTrigger>
    <DropdownMenuContent className="mt-4">
      <SolutionsMenuContent tracking={tracking} />
    </DropdownMenuContent>
  </DropdownMenu>
);

interface MobileMenuProps {
  user?: User | null;
  connectWalletConfig?: HeaderProps["connectWalletConfig"];
  showInsightsNavItem?: boolean;
}

const MobileNav: React.FC<MobileMenuProps> = ({ user, connectWalletConfig, showInsightsNavItem = false }) => (
  <div className="min-[1090px]:hidden w-full flex items-center justify-between px-4">
    <HeaderLogo />
    <div className="flex items-center space-x-1">
      <MobileMenu user={user} showInsightsNavItem={showInsightsNavItem} />
      {user && <UserMenu user={user} connectWalletConfig={connectWalletConfig} />}
    </div>
  </div>
);

const MobileMenu: React.FC<MobileMenuProps> = ({ user, showInsightsNavItem }) => {
  const pathname = typeof window !== "undefined" ? window.location.pathname : "";
  const isEarn = pathname.startsWith("/earn");

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost">
          <MenuIcon className="h-6 w-6" /> {/* Use MenuIcon as the trigger */}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={`flex flex-col justify-between p-0 pt-2 ${showInsightsNavItem ? "w-80 mt-4" : ""}`}
      >
        <div className="flex flex-col pb-2">
          {showInsightsNavItem ? (
            <>
              <DropdownMenuItem>
                <a href="/insights">
                  <div className="flex items-center">
                    <img
                      src="https://flipsidecrypto.xyz/images/icons/flip-ai.svg"
                      alt="Insights Icon"
                      className="w-3 h-3 mr-1"
                    />
                    <Text variant="button">Insights</Text>
                  </div>
                </a>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <a href="/insights/dashboards" className="ml-4 py-2">
                  <Text variant="button">Dashboards</Text>
                </a>
              </DropdownMenuItem>
              <Accordion type="multiple" defaultValue={["item-1"]} className="px-3 ml-4">
                <AccordionItem value="item-1" className="border-none">
                  <AccordionTrigger className="!no-underline py-2">
                    <Text variant="button">Solutions</Text>
                  </AccordionTrigger>
                  <AccordionContent>
                    <SolutionsMenuContent />
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem value="item-2" className="border-none">
                  <AccordionTrigger className="!no-underline py-4">
                    <Text variant="button">About</Text>
                  </AccordionTrigger>
                  <AccordionContent>
                    <AboutMenuContent />
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
              <div className="flex items-center justify-around mx-2">
                <a href="/studio" className="mx-1 hidden md:block w-1/2">
                  <Button
                    variant="outline"
                    className={cn("border-black w-1/2 min-w-full max-w-full dark:border-primary", {
                      "border-primary": isEarn,
                    })}
                  >
                    Studio
                    <MoveUpRight className="size-4 ml-2" />
                  </Button>
                </a>
                <a href="/earn" className="mx-1 block w-1/2 flex-grow">
                  <Button
                    variant="outline"
                    className={cn("border-black w-1/2 min-w-full max-w-full dark:border-primary", {
                      "border-primary": isEarn,
                    })}
                  >
                    Quests
                    <MoveUpRight className="size-4 ml-2" />
                  </Button>
                </a>
              </div>
            </>
          ) : (
            <>
              <DropdownMenuItem>
                <a href="/insights/dashboards">
                  <Text variant="button">Insights</Text>
                </a>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <a href="/earn/">
                  <Text variant="button">Quests</Text>
                </a>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <a href="/home/chains">
                  <Text variant="button">For Chains</Text>
                </a>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <a href="/home/analysts">
                  <Text variant="button">For Analysts</Text>
                </a>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <a href="/home/explorers">
                  <Text variant="button">For Explorers</Text>
                </a>
              </DropdownMenuItem>
              <AboutMenuContent />
            </>
          )}
        </div>
        {!user && (
          <div className="flex p-4 gap-x-4">
            <>
              <HeaderAuthButton variant="outline" href="/home/login" className="w-full max-w-full">
                Log in
              </HeaderAuthButton>
              <HeaderAuthButton variant="default" href="/home/login?screen_hint=signup" className="w-full max-w-full">
                Sign up
              </HeaderAuthButton>
            </>
          </div>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
