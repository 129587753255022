import type React from "react";
import { cn } from "../utils/cn";

interface AnalystsIconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
  title?: string;
}

export function AnalystsIcon({ className, title = "Analysts Icon", ...props }: AnalystsIconProps) {
  // Ensure title is never empty for accessibility
  const accessibleTitle = title || "Analysts Icon";

  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
      aria-hidden={!accessibleTitle}
      {...props}
    >
      <title>{accessibleTitle}</title>
      <g clipPath="url(#clip0_4035_4111)">
        <g>
          <path d="M16.8598 16.5517V0.550781H14.8831L14.8831 16.5517H16.8598Z" fill="currentColor" />
          <path d="M8.86103 4.551V16.5517H6.88441L6.88441 4.551H8.86103Z" fill="currentColor" />
          <path d="M4.86488 9.55132V16.5517H2.88825V9.55132H4.86488Z" fill="currentColor" />
          <path d="M17.8721 20.5394H1.87207V18.5628H17.8721V20.5394Z" fill="currentColor" />
          <path d="M12.8604 16.5517V9.55132H10.8838V16.5517H12.8604Z" fill="currentColor" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4035_4111">
          <rect width="20" height="20" fill="white" transform="translate(0 0.550781)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AnalystsIcon;
