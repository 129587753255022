import type React from "react";
import { cn } from "../utils/cn";

interface BlockchainsIconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
  title?: string;
}

export function BlockchainsIcon({ className, title = "Blockchains Icon", ...props }: BlockchainsIconProps) {
  // Ensure title is never empty for accessibility
  const accessibleTitle = title || "Blockchains Icon";

  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
      aria-hidden={!accessibleTitle}
      {...props}
    >
      <title>{accessibleTitle}</title>
      <g id="Icon/Blockchains">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3082 1.96672L10 1.80078L9.69183 1.96672L4.97912 4.50433C4.66273 4.21414 4.24094 4.03702 3.77778 4.03702C2.79594 4.03702 2 4.83296 2 5.8148C2 6.60637 2.51734 7.2771 3.23235 7.50734V13.3626V13.7508L3.57419 13.9349L8.24696 16.451C8.23069 16.5476 8.22222 16.6469 8.22222 16.7482C8.22222 17.73 9.01816 18.5259 10 18.5259C10.9818 18.5259 11.7778 17.73 11.7778 16.7482C11.7778 16.6469 11.7693 16.5476 11.753 16.451L16.4258 13.9349L16.7676 13.7508V13.3626V7.50734C17.4827 7.2771 18 6.60637 18 5.8148C18 4.83296 17.2041 4.03702 16.2222 4.03702C15.7591 4.03702 15.3373 4.21414 15.0209 4.50433L10.3082 1.96672ZM14.45 5.67341L10 3.27726L5.55002 5.67341C5.55369 5.72006 5.55556 5.76721 5.55556 5.8148C5.55556 6.52686 5.13692 7.14114 4.53235 7.42495V12.9743L8.91927 15.3365C9.21876 15.1069 9.59344 14.9704 10 14.9704C10.4066 14.9704 10.7812 15.1069 11.0807 15.3365L15.4676 12.9743V7.42495C14.8631 7.14114 14.4444 6.52686 14.4444 5.8148C14.4444 5.76721 14.4463 5.72005 14.45 5.67341ZM10.5491 6.7004C10.2097 6.50008 9.79051 6.50008 9.45108 6.7004L7.87711 7.62933C7.54052 7.82797 7.33342 8.19348 7.33342 8.58885V10.4448C7.33342 10.8402 7.54052 11.2057 7.87711 11.4043L9.45108 12.3333C9.79051 12.5336 10.2097 12.5336 10.5491 12.3333L12.1231 11.4043C12.4596 11.2057 12.6667 10.8402 12.6667 10.4448V8.58885C12.6667 8.19348 12.4596 7.82797 12.1231 7.62933L10.5491 6.7004Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default BlockchainsIcon;
