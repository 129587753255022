import type React from "react";
import { cn } from "../utils/cn";

interface ExplorerIconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
  title?: string;
}

export function ExplorerIcon({ className, title = "Explorer Icon", ...props }: ExplorerIconProps) {
  // Ensure title is never empty for accessibility
  const accessibleTitle = title || "Explorer Icon";

  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
      aria-hidden={!accessibleTitle}
      {...props}
    >
      <title>{accessibleTitle}</title>
      <g id="Icon/Explorer">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.38731 2.74219H4.68731L4.68731 3.44219L4.68731 4.65091C4.08137 4.79522 3.52699 5.12434 3.09232 5.59762C2.4965 6.24636 2.1709 7.11443 2.1709 8.00839C2.1709 8.90234 2.4965 9.77041 3.09232 10.4192C3.57541 10.9452 4.20637 11.2931 4.89164 11.4072C5.13404 12.3216 5.587 13.1677 6.22289 13.86C7.0503 14.7609 8.13321 15.341 9.30016 15.5079V17.1514H6V18.5514H14V17.1514H10.7002V15.5079C11.8673 15.3411 12.9503 14.7611 13.7778 13.86C14.4137 13.1677 14.8667 12.3216 15.1091 11.4071C15.7943 11.293 16.4252 10.9451 16.9082 10.4192C17.504 9.77041 17.8296 8.90234 17.8296 8.00839C17.8296 7.11443 17.504 6.24636 16.9082 5.59762C16.4736 5.12439 15.9193 4.79529 15.3134 4.65096V3.44219V2.74219H14.6134H10.0004H5.38731ZM4.12344 6.54461C4.28985 6.36341 4.48168 6.22331 4.68731 6.12668L4.68731 9.83487L4.68756 9.89022C4.48183 9.79358 4.28992 9.65344 4.12344 9.47217C3.7755 9.09332 3.5709 8.56767 3.5709 8.00839C3.5709 7.4491 3.7755 6.92346 4.12344 6.54461ZM15.8771 9.47217C15.7107 9.65338 15.5188 9.79348 15.3132 9.89012L15.3134 9.83487L15.3134 6.12677C15.519 6.22341 15.7107 6.36347 15.8771 6.54461C16.225 6.92346 16.4296 7.44911 16.4296 8.00839C16.4296 8.56767 16.225 9.09332 15.8771 9.47217ZM6.08731 9.83487L6.08731 4.14219H10.0004H13.9134L13.9134 9.83487C13.9134 10.9997 13.4879 12.106 12.7467 12.913C12.0074 13.7181 11.018 14.1577 10.0004 14.1577C8.98271 14.1577 7.99334 13.7181 7.254 12.913C6.51283 12.106 6.08731 10.9997 6.08731 9.83487Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default ExplorerIcon;
