import clsx from "clsx";
import { DiscordIcon } from "./DiscordIcon";
import { LinkedInIcon } from "./LinkedInIcon";
import { XIcon } from "./XIcon";

interface SocialIconProps {
  type: "x" | "discord" | "linkedin";
  className?: string;
  href: string;
}

const socialLabels = {
  x: "Follow us on X (formerly Twitter)",
  discord: "Join our Discord community",
  linkedin: "Connect with us on LinkedIn",
};

export function SocialIcon({ type, className, href }: SocialIconProps) {
  return (
    <a
      href={href}
      className={clsx("inline-flex", className)}
      target="_blank"
      aria-label={socialLabels[type]}
      rel="noreferrer"
    >
      {getIcon(type)}
    </a>
  );
}

const getIcon = (type: SocialIconProps["type"]) => {
  switch (type) {
    case "x":
      return <XIcon />;
    case "discord":
      return <DiscordIcon />;
    case "linkedin":
      return <LinkedInIcon className="size-4" />;
  }
};
